import React from "react"
import { container, title, subTitle } from "./reference.module.css"
import { useStaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"

export default function Reference() {
  const data = useStaticQuery(graphql`
    {
      reference: file(relativePath: { eq: "reference.md" }) {
        childMarkdownRemark {
          frontmatter {
            title_de
            referenceUrl
            referenceAuthor_de
          }
        }
      }
    }
  `)
  return (
    <div className={container}>
      <h2 className={title}>
        "{data.reference.childMarkdownRemark.frontmatter.title_de}"
      </h2>
      <OutboundLink
        className={subTitle}
        href={data.reference.childMarkdownRemark.frontmatter.referenceUrl}
      >
        {data.reference.childMarkdownRemark.frontmatter.referenceAuthor_de}
      </OutboundLink>
    </div>
  )
}
