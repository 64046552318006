import React from "react"
import Container from "../components/container/container"
import TitlePreview from "../components/titlePreview/titlePreview.de"
import Features from "../components/features/features.de"
import AppStores from "../components/appStores/appStores.de"
import Navigation from "../components/navigation/navigation.de"
import Footer from "../components/footer/footer"
import Seo from "../components/seo/seo.de"
import Reference from "../components/reference/reference.de"
import Attribution from "../components/attribution/attribution.de"
import Legal from "../components/legal/legal.de"

export default function Home() {
  return (
    <Container>
      <Seo lang="de" />
      <Navigation />
      <TitlePreview />
      <Attribution />
      <Reference />
      <Features />
      <AppStores />
      <Footer />
      <Legal />
    </Container>
  )
}
