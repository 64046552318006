import React from "react"
import {
  container,
  attributionImage,
  attributionImageButton,
  attributionImageContainer,
  attributionContent,
  attributionTitle,
  attributionSubtitle,
} from "./attribution.module.css"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Attribution() {

  const data = useStaticQuery(graphql`
    {
      attributionContent: file(relativePath: { eq: "attribution_philipp.md" }) {
        childMarkdownRemark {
          frontmatter {
            title_de
            subtitle_de
            image_desc_de
            call_to_action_de
          }
        }
      }
      attributionImage: file(
        relativePath: { eq: "de/wie-verliere-ich-bauchfett-am-schnellsten.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  `)

  const imageData = data.attributionImage.childImageSharp.gatsbyImageData
  const imageDescData = data.attributionContent.childMarkdownRemark.frontmatter.image_desc_de
  const imageDataCallToAction = data.attributionContent.childMarkdownRemark.frontmatter.call_to_action_de
  const titleData = data.attributionContent.childMarkdownRemark.frontmatter.title_de
  const subtitleData = data.attributionContent.childMarkdownRemark.frontmatter.subtitle_de
  const articleSlug = "/de/blog/wie-verliert-man-am-schnellsten-fett-am-bauch"

  return (
    <div className={container}>
      <div className={attributionContent}>
        <h2 className={attributionTitle}>"{titleData}"</h2>
        <p className={attributionSubtitle}>{subtitleData}</p>
      </div>
      <Link to={articleSlug} className={attributionImageContainer}>
        <GatsbyImage
          image={imageData}
          className={attributionImage}
          alt={imageDescData}
        />
        <div className={attributionImageButton}>{imageDataCallToAction}</div>
      </Link>
    </div>
  )
}
