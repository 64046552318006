import React from "react"
import FeatureReference from "./featureReference"
import Feature from "./feature"
import FeatureImageRight from "./featureImageRight"
import Attribution from "../attributionPeter/attributionPeter.de"
import { useStaticQuery, graphql } from "gatsby"

const Container = ({ children }) => <div styles={{}}>{children}</div>

export default function Features() {
  const data = useStaticQuery(graphql`
    {
      firstContent: file(relativePath: { eq: "content_2.md" }) {
        childMarkdownRemark {
          frontmatter {
            subtitle_de
            title_de
            imageDesc_de
          }
        }
      }
      firstImage: file(
        relativePath: { eq: "de/kalorienzähler-app-kostenlos.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      secondContent: file(relativePath: { eq: "content_6.md" }) {
        childMarkdownRemark {
          frontmatter {
            subtitle_de
            title_de
            imageDesc_de
          }
        }
      }
      secondImage: file(relativePath: { eq: "de/barcode-kalorien-app.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      thirdContent: file(relativePath: { eq: "content_5.md" }) {
        childMarkdownRemark {
          frontmatter {
            subtitle_de
            title_de
            imageDesc_de
          }
        }
      }
      thirdImage: file(relativePath: { eq: "de/rezepte-erstellen-app.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      fourthContent: file(relativePath: { eq: "content_7.md" }) {
        childMarkdownRemark {
          frontmatter {
            subtitle_de
            title_de
            imageDesc_de
          }
        }
      }
      fourthImage: file(relativePath: { eq: "de/kalorienrechner-rezepte.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      fifthContent: file(relativePath: { eq: "content_3.md" }) {
        childMarkdownRemark {
          frontmatter {
            subtitle_de
            title_de
            imageDesc_de
          }
        }
      }
      fifthImage: file(relativePath: { eq: "de/gewichtstagebuch-app.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      sixthContent: file(relativePath: { eq: "content_4.md" }) {
        childMarkdownRemark {
          frontmatter {
            subtitle_de
            title_de
            imageDesc_de
          }
        }
      }
      sixthImage: file(
        relativePath: { eq: "de/gewicht-tagebuch.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      seventhContent: file(relativePath: { eq: "content_8.md" }) {
        childMarkdownRemark {
          frontmatter {
            subtitle_de
            title_de
            imageDesc_de
          }
        }
      }
      seventhImage: file(relativePath: { eq: "de/kohlenhydrate-rechner-app-kostenlos.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      eighthContent: file(relativePath: { eq: "content_1.md" }) {
        childMarkdownRemark {
          frontmatter {
            subtitle_de
            title_de
            imageDesc_de
          }
        }
      }
      eighthImage: file(relativePath: { eq: "de/kalorienrechner.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      ninthImage: file(relativePath: { eq: "de/foodtracker-app.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      ninthContent: file(relativePath: { eq: "app_stores_link.md" }) {
        childMarkdownRemark {
          frontmatter {
            title_de
            subtitle_de
            imageDesc_de
          }
        }
      }
      tenthImage: file(relativePath: { eq: "de/wie-tracke-ich-am-besten-meine-kalorien.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      tenthContent: file(relativePath: { eq: "content_9.md" }) {
        childMarkdownRemark {
          frontmatter {
            title_de
            subtitle_de
            imageDesc_de
          }
        }
      }
    }
  `)
  return (
    <Container>
      <Feature
        excerpt={data.firstContent.childMarkdownRemark.frontmatter.subtitle_de}
        title={data.firstContent.childMarkdownRemark.frontmatter.title_de}
        screenshot={data.firstImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.firstContent.childMarkdownRemark.frontmatter.imageDesc_de
        }
      />
      <FeatureImageRight
        excerpt={data.secondContent.childMarkdownRemark.frontmatter.subtitle_de}
        title={data.secondContent.childMarkdownRemark.frontmatter.title_de}
        screenshot={data.secondImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.secondContent.childMarkdownRemark.frontmatter.imageDesc_de
        }
      />
      <Feature
        excerpt={data.thirdContent.childMarkdownRemark.frontmatter.subtitle_de}
        title={data.thirdContent.childMarkdownRemark.frontmatter.title_de}
        screenshot={data.thirdImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.thirdContent.childMarkdownRemark.frontmatter.imageDesc_de
        }
      />
      <FeatureImageRight
        excerpt={data.fourthContent.childMarkdownRemark.frontmatter.subtitle_de}
        title={data.fourthContent.childMarkdownRemark.frontmatter.title_de}
        screenshot={data.fourthImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.fourthContent.childMarkdownRemark.frontmatter.imageDesc_de
        }
      />
      <FeatureReference
        articleSlug={"/de/blog/welche-app-ist-kompatibel-mit-fitbit"}
        excerpt={data.tenthContent.childMarkdownRemark.frontmatter.subtitle_de}
        title={data.tenthContent.childMarkdownRemark.frontmatter.title_de}
        screenshot={data.tenthImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.tenthContent.childMarkdownRemark.frontmatter.imageDesc_de
        }
      />
      <Attribution />
      <FeatureImageRight
        excerpt={data.fifthContent.childMarkdownRemark.frontmatter.subtitle_de}
        title={data.fifthContent.childMarkdownRemark.frontmatter.title_de}
        screenshot={data.fifthImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.fifthContent.childMarkdownRemark.frontmatter.imageDesc_de
        }
      />
      <Feature
        excerpt={data.sixthContent.childMarkdownRemark.frontmatter.subtitle_de}
        title={data.sixthContent.childMarkdownRemark.frontmatter.title_de}
        screenshot={data.sixthImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.sixthContent.childMarkdownRemark.frontmatter.imageDesc_de
        }
      />
      <FeatureImageRight
        excerpt={
          data.seventhContent.childMarkdownRemark.frontmatter.subtitle_de
        }
        title={data.seventhContent.childMarkdownRemark.frontmatter.title_de}
        screenshot={data.seventhImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.seventhContent.childMarkdownRemark.frontmatter.imageDesc_de
        }
      />
      <Feature
        excerpt={data.eighthContent.childMarkdownRemark.frontmatter.subtitle_de}
        title={data.eighthContent.childMarkdownRemark.frontmatter.title_de}
        screenshot={data.eighthImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.eighthContent.childMarkdownRemark.frontmatter.imageDesc_de
        }
      />
      <FeatureImageRight
        excerpt={data.ninthContent.childMarkdownRemark.frontmatter.subtitle_de}
        title={data.ninthContent.childMarkdownRemark.frontmatter.title_de}
        screenshot={data.ninthImage.childImageSharp.gatsbyImageData}
        imageDesc={
          data.ninthContent.childMarkdownRemark.frontmatter.imageDesc_de
        }
      />
    </Container>
  )
}
